import React from "react"

import {
  FaEnvelope as Mail,
} from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>kois : </span> <br />
      </div>
      <div className="h1 code mt-4 mb-3"> {"{"}</div>
      <div className="text-muted mx-5 my-4 h3 text-justify info">
      We are Kois Group Ltd, a leading system integration company based in the UK. We have an experienced team of creative and innovative individuals who are committed to working with you to deliver the solutions you and your business deserve.   </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
      <div className="h1 mt-5">
        <a className="mr-5 icon" href="mailto://info@kois.io">
          <Mail />
        </a>
      </div>
    </div>
  )
}
